import React, { useEffect } from 'react';
import anime from 'animejs/lib/anime.es';
import Gallery from '../Gallery';
import Popup from '../Popup';

const animOptions = {
  duration: 2000,
  direction: 'alternate',
  easing: 'linear',
  loop: true,
  autoplay: false,
};

const d =
  'M149.000,0.000 C217.134,-1.324 262.640,63.091 240.000,137.000 C223.096,192.186 169.181,237.570 91.000,214.000 C40.969,198.917 -32.046,140.865 16.000,74.000 C31.568,52.334 64.820,51.267 91.000,40.000 C105.336,33.830 110.900,21.656 122.000,13.000 C130.115,6.671 136.797,2.195 149.000,0.000 Z';

const w = 4019.31;
const h = 3646.57;

export default function Bubble4({
  show,
  toggleShow,
  galleryShow,
  toggleGalleryShow,
  content: {
    bubbles: { bubble4: bubble },
  },
  isAnimate,
  toggleBubbleAnimation,
}) {
  useEffect(() => {
    const anime1 = anime({
      ...animOptions,
      targets: '.bubble-4-bg path',
      d: [
        {
          value:
            'M151.000,1.000 C219.134,-0.324 265.640,48.091 243.000,122.000 C226.096,177.186 172.181,222.570 94.000,199.000 C43.969,183.917 -32.046,148.865 16.000,82.000 C31.568,60.334 56.820,49.267 83.000,38.000 C97.336,31.830 112.900,22.656 124.000,14.000 C132.115,7.671 140.832,5.844 151.000,1.000 Z',
        },
      ],
    });

    const anime2 = anime({
      ...animOptions,
      targets: '.bubble-4-left path',
      d: [
        {
          value:
            'M96.000,1.000 C132.065,-3.635 153.845,25.305 147.000,64.000 C144.275,79.405 133.038,102.371 136.000,122.000 C140.316,150.603 181.571,196.264 125.000,206.000 C59.807,217.220 -15.262,133.092 4.000,69.000 C18.590,20.453 65.337,6.529 96.000,1.000 Z',
        },
      ],
    });

    toggleBubbleAnimation(
      isAnimate,
      [anime1, anime2],
      ['.bubble-4-bg path', '.bubble-4-left path']
    );
  }, [isAnimate, toggleBubbleAnimation]);

  return (
    <>
      <button
        onClick={toggleShow}
        type="button"
        className="no-btn-styles bubble bubble-4"
      >
        <svg
          className="bubble-item bubble-4-bg"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="mask-s">
            <path fill="rgb(0, 0, 0)" d={d} />
          </clipPath>
          <image
            clipPath="url(#mask-s)"
            href={require('../../assets/images/bubble4/bg.gif')}
          />
        </svg>
        <svg
          className="bubble-item bubble-4-left"
          xmlns="http://www.w3.org/2000/svg"
          width="157px"
          height="207px"
        >
          <path
            fillRule="evenodd"
            fill="#f87504"
            d="M96.000,-0.000 C133.793,-0.653 144.426,20.704 137.000,63.000 C134.295,78.408 126.491,96.461 130.000,116.000 C135.869,148.679 181.571,195.264 125.000,205.000 C59.807,216.220 -15.262,142.092 4.000,78.000 C18.590,29.453 63.843,1.234 96.000,-0.000 Z"
          />
        </svg>
        <span className="bubble-item text-center bubble-4-text">
          Movies that <br /> Move You
        </span>
        <img
          className="bubble-item bubble-4-play"
          src={require('../../assets/images/play-icon.svg')}
          alt=""
        />
      </button>

      <Popup show={show} toggleShow={toggleShow}>
        <div className="popup-shape">
          <div
            className="popup-shape-block"
            style={{ paddingTop: `${(h / w) * 100}%` }}
          />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w} ${h}`}>
            <path
              fillRule="evenodd"
              fill="rgb(194, 176, 138)"
              d="M2525.71 5.3c792.58,-76.55 1375.28,690.87 1473.48,1399.76 93.06,671.82 -142.61,1309.66 -586.93,1732.34 -754.19,717.47 -1990.19,637.3 -2773.41,5.92 -715.03,-576.38 -977.3,-1928.6 -2.95,-2208.13 398.31,-114.27 671.12,-198.36 872.52,-294.77 466.13,-223.14 424.54,-577.87 1017.28,-635.12z"
            />
          </svg>
          <div className="popup-content" style={{ paddingTop: '23%' }}>
            <div className="px-0 col-xl-8 mx-auto popup-content-body color-base">
              <h2 className="popup-content-title">{bubble.title}</h2>
              <p className="popup-content-sentence">{bubble.sentence}</p>
              <button
                onClick={toggleGalleryShow}
                type="button"
                className="btn-primary mb-3"
              >
                View our work
              </button>
              <video
                poster={require('../../assets/images/bubble4/bg.jpg')}
                className="popup-content-media lg"
                controls
              >
                <source
                  src={require(`../../assets/images/${bubble.img}`)}
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </Popup>
      <Gallery
        isVideos
        show={galleryShow}
        handleShow={toggleGalleryShow}
        images={bubble.gallery}
      />
    </>
  );
}
