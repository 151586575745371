import React, { useState, useEffect } from 'react';
import ContentContext from '../contexts/ContentContext';
import Axios from 'axios';

export default function AppWrapper({ children }) {
  const [content, setContent] = useState({ isLoaded: false });

  useEffect(() => {
    Axios(`${process.env.PUBLIC_URL}/copy.json`)
      .then((d) => setContent({ ...d.data, isLoaded: true }))
      .catch((err) => console.error(err));
  }, []);

  return (
    <ContentContext.Provider value={content}>
      {children}
    </ContentContext.Provider>
  );
}
