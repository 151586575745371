import { useState } from 'react';
import useContent from '../hooks/useContent';

export default function BubblesWrapper({ render }) {
  const [show, setShow] = useState(false);
  const [galleryShow, setGalleryShow] = useState(false);
  const toggleShow = () => setShow(!show);
  const toggleGalleryShow = () => setGalleryShow(!galleryShow);
  const content = useContent();

  return render({
    show,
    galleryShow,
    toggleGalleryShow,
    toggleShow,
    content,
  });
}
