import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function DocumentMeta({ title = '', meta }) {
  return (
    <Helmet>
      <title>{title || 'Bhaav Digital'}</title>
      {Object.keys(meta).map((key, index) => (
        <meta key={key + index} name={key} content={meta[key]} />
      ))}
    </Helmet>
  );
}
