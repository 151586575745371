import React, { useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import closeIcon from '../assets/images/close-icon.svg';
import { createPortal } from 'react-dom';

import 'react-image-gallery/styles/scss/image-gallery.scss';
import '../styles/components/gallery-modal.scss';

function pauseVideo() {
  const videos = document.querySelectorAll('video');
  videos.forEach((video) => {
    if (!video.paused) {
      video.pause();
    }
  });
}

export default function Gallery({ show, handleShow, images }) {
  const items = images.map((img) => {
    const isImageHttp = src => src.includes('http');
    return {
      original: isImageHttp(img.src) ? img.src : require(`../assets/images/${img.src}`),
      thumbnail: isImageHttp(img.src) ? img.src : require(`../assets/images/${img.src}`),
      type: img.type || 'image',
      videoThumb:
        img.type === 'video' && img.thumbnail
          ? isImageHttp(img.thumbnail) ? img.thumbnail : require(`../assets/images/${img.thumbnail}`)
          : null,
    }
  });

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      const key = e.key;
      if (key && key.toLowerCase() === 'escape' && show) {
        handleShow();
      }
    });
  }, [handleShow, show]);

  return show
    ? createPortal(
        <div className="gallery-modal">
          <button onClick={handleShow} type="buton" className="popup-close">
            <img src={closeIcon} alt="" />
          </button>
          <div className="gallery-modal-container">
            <ImageGallery
              showPlayButton={false}
              showFullscreenButton={false}
              items={items}
              onSlide={pauseVideo}
              renderItem={(item) =>
                item.type === 'video' ? (
                  <video
                    className="image-gallery-video"
                    src={item.original}
                    controls
                    preload="metadata"
                  ></video>
                ) : (
                  <img
                    className="image-gallery-image"
                    src={item.original}
                    alt=""
                  />
                )
              }
              renderThumbInner={(thumb) => (
                <div className="image-gallery-thumbnail-inner">
                  <img
                    className="image-gallery-thumbnail-image"
                    src={thumb.videoThumb || thumb.thumbnail}
                    alt=""
                  />
                  {thumb.videoThumb && (
                    <div className="image-gallery-thumbnail-icon">&#9658;</div>
                  )}
                </div>
              )}
            />
          </div>
          <div className="gallery-modal-backdrop" />
        </div>,
        document.querySelector('#modal-root')
      )
    : null;
}
