import React, { useEffect } from 'react';
import anime from 'animejs/lib/anime.es';
import Gallery from '../Gallery';
import Popup from '../Popup';

const animOptions = {
  duration: 2000,
  direction: 'alternate',
  easing: 'linear',
  loop: true,
  autoplay: false,
};

const d =
  'M73.000,1.000 C132.361,0.002 179.019,6.657 201.000,43.000 C248.650,121.786 176.651,247.330 110.000,259.000 C94.048,261.793 80.670,255.135 72.000,250.000 C26.797,223.228 21.499,184.178 8.000,125.000 C2.882,102.564 -4.182,59.511 4.000,37.000 C16.734,1.963 40.200,1.562 73.000,1.000 Z';

const w = 2042.67;
const h = 2437.35;

export default function Bubble8({
  show,
  toggleShow,
  content: {
    bubbles: { bubble8: bubble },
  },
  isAnimate,
  galleryShow,
  toggleGalleryShow,
  toggleBubbleAnimation,
}) {
  useEffect(() => {
    const anime1 = anime({
      ...animOptions,
      targets: '#bubble-8-bg path',
      d: [
        {
          value:
            'M78.000,5.000 C137.361,4.002 157.019,-11.343 179.000,25.000 C226.650,103.786 157.651,238.330 91.000,250.000 C75.048,252.793 61.670,246.135 53.000,241.000 C7.797,214.228 18.499,175.178 5.000,116.000 C-0.118,93.564 -3.093,63.484 9.000,41.000 C25.171,10.934 42.602,7.433 78.000,5.000 Z',
        },
      ],
    });
    const anime2 = anime({
      ...animOptions,
      targets: '.bubble-8-shape-right path',
      keyframes: [
        {
          rotate: '6deg',
          skewX: '5deg',
        },
        {
          skewY: '-3deg',
          skewX: '-3deg',
          rotate: '0deg',
        },
      ],
    });

    toggleBubbleAnimation(
      isAnimate,
      [anime1, anime2],
      ['#bubble-8-bg path', '.bubble-8-shape-right path']
    );
  }, [isAnimate, toggleBubbleAnimation]);

  return (
    <>
      <button
        onClick={toggleShow}
        type="button"
        className="no-btn-styles bubble bubble-8"
      >
        <svg
          className="bubble-item bubble-8-bg"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="bubble-8-bg">
            <path fillRule="evenodd" fill="rgb(0, 0, 0)" d={d} />
          </clipPath>
          <image
            x="-34"
            y="-31"
            clipPath="url(#bubble-8-bg)"
            href={require('../../assets/images/bubble8/bg.jpg')}
          />
        </svg>
        <svg
          className="bubble-item bubble-8-shape-right"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            fill="rgba(130, 91, 78)"
            d="M34.000,1.000 C75.139,-0.038 93.448,29.955 100.000,63.000 C103.631,81.311 101.158,110.889 89.000,117.000 C87.667,117.333 86.333,117.667 85.000,118.000 C76.668,110.334 68.332,102.666 60.000,95.000 C47.777,88.068 33.734,87.540 23.000,80.000 C-13.098,54.643 -3.055,7.873 34.000,1.000 Z"
          />
        </svg>
        <span className="bubble-item bubble-8-text">Print Vrint</span>
      </button>
      <Popup show={show} toggleShow={toggleShow}>
        <div className="popup-shape">
          <div
            className="popup-shape-block"
            style={{ paddingTop: `${(h / w) * 100}%` }}
          />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w} ${h}`}>
            <path
              fillRule="evenodd"
              fill="#dfd8cd"
              d="M5.76 659.09c0,0 -113.51,-613.78 667.73,-653.96 442.53,-22.76 823.8,26.26 1070.36,225.96 479.42,388.33 298.57,1143.36 35.91,1602.11 -177.79,310.51 -543.37,687.89 -940.37,587.67 -649.01,-163.82 -771.58,-1186.8 -833.63,-1761.79z"
            />
          </svg>
          <div className="popup-content" style={{ paddingTop: '20%' }}>
            <div className="popup-content-body">
              <div className="row align-items-xl-center">
                <div className="col-xl-6">
                  <h2 className="popup-content-title color-red">
                    {bubble.title}
                  </h2>
                  <p className="popup-content-sentence">{bubble.sentence}</p>
                  <button
                    onClick={toggleGalleryShow}
                    type="button"
                    className="btn-primary btn-primary-dark"
                  >
                    View our work
                  </button>
                </div>
                <div className="col-xl-5 order-first order-xl-last">
                  <img
                    className="w-100 mb-3"
                    src={require(`../../assets/images/${bubble.img}`)}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <Gallery
        show={galleryShow}
        handleShow={toggleGalleryShow}
        images={bubble.gallery}
      />
    </>
  );
}
