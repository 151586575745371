import React from 'react';
import useContent from '../hooks/useContent';

import '../styles/components/social-icons.scss';

export default function SocialIcons() {
  const { socials } = useContent();

  const iconsMap = {
    facebook: 'icon-fb.png',
    twitter: 'icon-tw.png',
    linkedin: 'icon-in.png',
    instagram: 'icon-insta.png',
    youtube: 'icon-yt.png',
  };

  return (
    <ol className="social-icons">
      {socials.map((social, index) => (
        <li key={`social_${index}`}>
          <a target="_blank" rel="noopener noreferrer" href={social.url}>
            <img
              src={require(`../assets/images/${iconsMap[social.handle]}`)}
              alt=""
            />
          </a>
        </li>
      ))}
    </ol>
  );
}
