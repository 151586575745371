import React, { useEffect } from 'react';
import handleViewport from 'react-in-viewport';
import { setAnimationDelay } from '../utils/helpers';
import BubblesWrapper from './BubblesWrapper';
import Bubble1 from './Bubbles/Bubble1';
import Bubble2 from './Bubbles/Bubble2';
import Bubble3 from './Bubbles/Bubble3';
import Bubble4 from './Bubbles/Bubble4';
import Bubble5 from './Bubbles/Bubble5';
import Bubble6 from './Bubbles/Bubble6';
import Bubble7 from './Bubbles/Bubble7';
import Bubble8 from './Bubbles/Bubble8';

import '../styles/components/main-section.scss';

const bubbles = [
  Bubble1,
  Bubble2,
  Bubble3,
  Bubble4,
  Bubble5,
  Bubble6,
  Bubble7,
  Bubble8,
];

function MainSection({
  className = '',
  forwardedRef,
  inViewport,
  toggleBubbleAnimation,
  bubblesMap = bubbles,
}) {
  useEffect(() => {
    if (forwardedRef.current !== null && inViewport)
      setAnimationDelay('.main-section');
  }, [forwardedRef, inViewport]);

  return (
    <section ref={forwardedRef} className={`main-section ${className}`}>
      <div className="container">
        <div
          className={`row${
            bubblesMap.length <= 3
              ? ''
              : ' justify-content-between justify-content-md-around justify-content-lg-between'
          }`}
        >
          {bubblesMap.map((Bubble, index) => (
            <div key={`bubble_item_${index}`} className="main-section-item">
              <BubblesWrapper
                render={(props) => (
                  <div className="bubble-wrapper">
                    <Bubble
                      {...props}
                      toggleBubbleAnimation={toggleBubbleAnimation}
                      isAnimate={inViewport}
                    />
                  </div>
                )}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default handleViewport(MainSection);
