import React from 'react';
import { Link } from 'react-router-dom';
import useContent from '../hooks/useContent';
import Header from '../components/Header';
import Footer from '../components/Footer';

import '../styles/components/not-found-section.scss';

export default function NotFound() {
  const content = useContent();
  return (
    content.isLoaded && (
      <>
        <Header />
        <section className="not-found-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 mb-lg-0 mb-4">
                <h1>Oooops, Page not found!</h1>
                <p className="mb-4 f-18">
                  Looks like this page can't be found. Maybe it was moved or
                  renamed.
                </p>
                <Link
                  className="d-inline-block btn py-2 px-0 col-4 text-center"
                  to="/"
                >
                  Go to Home
                </Link>
              </div>
              <div className="col-lg-6 ml-auto">
                <img
                  className="img-fluid"
                  src={require('../assets/images/not-found.svg')}
                  alt="Not found"
                />
              </div>
            </div>
          </div>
          <img
            className="not-found-section-bg"
            src={require('../assets/images/wave-white.svg')}
            alt=""
          />
        </section>
        <Footer className="m-0" />
      </>
    )
  );
}
