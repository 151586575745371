import React from 'react';
import useContent from '../hooks/useContent';
import '../styles/components/our-client-section.scss';

export default function OurClientSection() {
  const { clients } = useContent();

  return (
    <section className="our-client-section">
      <h2 className="text-center">Our Clients</h2>
      <div className="container our-client-section-list">
        <div className="row align-items-center">
          {clients.map((client, index) => (
            <div
              key={`clieint-${index}`}
              className="col-lg-2 col-md-4 col-6 mb-lg-4 mb-3"
            >
              <img
                className="img-fluid d-block mx-auto"
                src={require(`../assets/images/clients/${client}`)}
                alt=""
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
