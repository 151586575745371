import React, { useEffect } from 'react';
import anime from 'animejs/lib/anime.es';
import Gallery from '../Gallery';
import Popup from '../Popup';

const animOptions = {
  duration: 1500,
  direction: 'alternate',
  easing: 'linear',
  loop: true,
  autoplay: false,
};

const d =
  'M100.000,-0.000 C164.375,-0.984 206.962,42.445 226.000,87.000 C234.445,106.762 241.647,151.870 222.000,165.000 C199.888,179.778 168.111,155.328 140.000,161.000 C107.415,167.575 74.051,209.543 31.000,192.000 C3.320,180.721 -6.063,137.127 5.000,100.000 C16.017,63.027 37.982,29.115 68.000,11.000 C77.565,5.228 88.736,4.817 100.000,-0.000 Z';

const w = 3773.29;
const h = 3161.09;

export default function Bubble3({
  show,
  toggleShow,
  galleryShow,
  toggleGalleryShow,
  content: {
    bubbles: { bubble3: bubble },
  },
  isAnimate,
  toggleBubbleAnimation,
}) {
  const anime1 = anime({
    ...animOptions,
    targets: '.bubble-3-bg path',
    d: [
      {
        value:
          'M118.000,-0.000 C182.375,-0.984 202.962,39.445 222.000,84.000 C230.445,103.762 237.647,148.870 218.000,162.000 C195.888,176.778 164.111,152.328 136.000,158.000 C103.415,164.575 74.051,206.543 31.000,189.000 C3.320,177.721 -6.063,134.127 5.000,97.000 C16.017,60.027 55.982,29.115 86.000,11.000 C95.565,5.228 106.736,4.817 118.000,-0.000 Z',
      },
    ],
  });

  const anime2 = anime({
    ...animOptions,
    targets: '.bubble-3-bg-bottom path',
    d: [
      {
        value:
          'M193.000,-0.000 C241.438,-1.137 231.283,65.303 217.000,100.000 C187.567,171.499 82.302,213.995 24.000,139.000 C16.308,129.106 9.467,116.759 5.000,104.000 C-13.466,51.259 22.343,14.952 68.000,7.000 C88.894,3.361 106.086,11.816 121.000,15.000 C146.783,20.505 176.137,8.824 193.000,-0.000 Z',
      },
    ],
  });

  useEffect(() => {
    toggleBubbleAnimation(
      isAnimate,
      [anime1, anime2],
      ['.bubble-3-bg path', '.bubble-3-bg-bottom path']
    );
  }, [anime1, anime2, isAnimate, toggleBubbleAnimation]);

  return (
    <>
      <button
        onClick={toggleShow}
        type="button"
        className="no-btn-styles bubble bubble-3"
      >
        <div className="smoke"></div>
        <svg
          className="bubble-item bubble-3-bg"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="mask">
            <path fill="rgb(0, 0, 0)" d={d} />
          </clipPath>
          <image
            clipPath="url(#mask)"
            href={require('../../assets/images/bubble3/bg.gif')}
          />
        </svg>
        <svg
          className="bubble-item bubble-3-bg-bottom"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#ec305a"
            d="M186.000,-0.000 C234.438,-1.137 232.283,59.303 218.000,94.000 C188.567,165.499 83.302,207.995 25.000,133.000 C17.308,123.106 10.467,110.759 6.000,98.000 C-12.466,45.259 15.343,12.952 61.000,5.000 C81.894,1.361 99.086,9.816 114.000,13.000 C139.783,18.505 169.137,8.824 186.000,-0.000 Z"
          />
        </svg>
        <span className="bubble-item bubble-3-text">
          A Moment in Time <br /> so Divine
        </span>
      </button>
      <Popup show={show} toggleShow={toggleShow}>
        <div className="popup-shape">
          <div
            className="popup-shape-block"
            style={{ paddingTop: `${(h / w) * 100}%` }}
          />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w} ${h}`}>
            <path
              fillRule="evenodd"
              fill="#252525"
              d="M765.02 452.27c0,0 629.19,-758.36 1633.39,-304.18 1004.2,454.18 1291.71,1412.55 1291.71,1412.55 0,0 113.34,395.25 69.72,727.5 -74.6,568.28 -455.76,441.23 -856.86,376.26 -163.11,-26.42 -319.5,-46.85 -475.33,-43.28 -581.24,13.32 -847.61,425.17 -1432.84,520.55 -277.22,45.18 -575.7,4.83 -796.48,-258.08 0,0 -400.02,-433.35 -54.17,-1475.05 0,0 191.66,-562.52 620.86,-956.28z"
            />
          </svg>
          <div className="row no-gutters align-items-center popup-content">
            <div className="col-xl-6 pr-xl-3">
              <h2 className="popup-content-title color-red">{bubble.title}</h2>
              <p className="popup-content-sentence color-white">
                {bubble.sentence}
              </p>
              <button
                onClick={toggleGalleryShow}
                type="button"
                className="btn-primary mb-3"
              >
                View our work
              </button>
            </div>
            <div className="col-xl-5">
              <img
                className="w-100"
                src={require(`../../assets/images/bubble3/${bubble.img}`)}
                alt=""
              />
            </div>
          </div>
        </div>
      </Popup>
      <Gallery
        isVideos
        show={galleryShow}
        handleShow={toggleGalleryShow}
        images={bubble.gallery}
      />
    </>
  );
}
