import React, { lazy, Suspense } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MainSection from "../components/MainSection";
import InnerContent from "../components/InnerContent";
import useContent from "../hooks/useContent";
import "../styles/components/content-section.scss";
import DocumentMeta from "../components/DocumentMeta";
import Image_one from "../assets/images/image_one.jpeg";
import Image_two from "../assets/images/image_two.jpeg";
import Image_three from "../assets/images/image_three.jpeg";

export default function ServicePage({ toggleBubbleAnimation }) {
  const { services, isLoaded } = useContent();
  const { pathname } = useLocation();

  if (!isLoaded) return null;

  const service = services.filter((s) => s.slug === pathname); 
  const bubbles = service[0].bubbles.map((b) =>
    lazy(() => import(`../components/Bubbles/Bubble${b}`))
  );

  return (
    <>
      <DocumentMeta title={service[0].title} meta={service[0].meta} />
      <Header />
      <Suspense fallback={<></>}>
        <MainSection
          toggleBubbleAnimation={toggleBubbleAnimation}
          className="service-page mb-0 pr-lg-4 pr-4"
          {...(bubbles.length ? { bubblesMap: bubbles } : null)}
        />
      </Suspense>
      <section className="content-section">
        <div className="container">
          <h1 className="content-section-title">{service[0].name}</h1>
          <div className="content-section-body">
            <InnerContent content={service[0].content} />
          </div>
        </div>
      </section>
      {service[0].title && service[0].title == "Healthcare Creative design Agency & Services | Bhaav" &&
      <>
      <section className="content-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-2">
              <div className="image_container">
                <img src={Image_one} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-4 mb-2">
              <div className="image_container">
                <img src={Image_two} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-4 mb-2">
              <div className="image_container">
                <img src={Image_three} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
}
      <Footer className="mt-0" />
    </>
  );
}
