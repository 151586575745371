import React, { useState, useRef } from 'react';
import axios from 'axios';

const initialValue = {
  name: '',
  phone: '',
  email: '',
  message: '',
};

const formData = new FormData();
const url = 'https://bhaav.co.in/sendmail/sendmail.php';
const config = {
  headers: { 'content-type': 'multipart/form-data' },
};

const loadingImg = <div className="loading" />;

export default function QueryForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [value, setValue] = useState(initialValue);
  const form = useRef(null);

  const handleChange = (e) => {
    const target = e.target;
    setValue((prevValue) => {
      return { ...prevValue, [target.name]: target.value.trim() };
    });
  };

  const removeMessage = () => setTimeout(() => setResult(null), 3400);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    Object.keys(initialValue).forEach((key) =>
      formData.append(key, value[key])
    );
    const { data } = await axios.post(url, formData, config);
    setResult(data);
    setIsLoading(false);
    removeMessage();
    form && form.current.reset();
  };

  return (
    <form ref={form} onSubmit={handleSubmit} className="footer-query-form mb-4">
      <input
        className="footer-query-form-input"
        type="text"
        placeholder="Name"
        name="name"
        onBlur={handleChange}
        required
      />
      <input
        className="footer-query-form-input"
        type="tel"
        placeholder="Phone number"
        name="phone"
        onBlur={handleChange}
        required
      />
      <input
        className="footer-query-form-input"
        type="email"
        placeholder="Email id"
        name="email"
        onBlur={handleChange}
        required
      />
      <textarea
        className="footer-query-form-input"
        cols="30"
        rows="4"
        placeholder="Message"
        name="message"
        onBlur={handleChange}
        required
        minLength="10"
      />
      <button className="footer-query-form-btn col-4" type="submit">
        {isLoading ? loadingImg : 'Submit'}
      </button>
      <p className="f-18 color-white text-center mt-2 mb-lg-0">
        {result && result.message}
      </p>
    </form>
  );
}
