export const servicesPage = [
  { path: '/service/healthcare-integrated-marketing-agency', name: 'Integrated Marketing' },
  { path: '/service/integrated-marketing', name: 'Integrated Marketing' },
  { path: '/service/healthcare-creative-agency', name: 'Creatives & Branding' },
  { path: '/service/creatives-branding', name: 'Creatives & Branding' },
  { path: '/service/healthcare-digital-marketing-agency', name: 'Digital Marketing' },
  { path: '/service/digital-marketing', name: 'Digital Marketing' },
  { path: '/service/healthcare-seo-agency', name: 'SEO Campaigns' },
  { path: '/service/seo-campaigns', name: 'SEO Campaigns' },
  { path: '/service/healthcare-search-engine-marketing-agency', name: 'SEM Campaigns' },
  { path: '/service/sem-campaigns', name: 'SEM Campaigns' },
  { path: '/service/healthcare-social-media-marketing-agency', name: 'Social Media Campaigns' },
  { path: '/service/social-media-campaigns', name: 'Social Media Campaigns' },
  { path: '/service/healthcare-website-development-agency', name: 'Website Development' },
  { path: '/service/website-development', name: 'Website Development' },
  { path: '/service/healthcare-content-writing-agency', name: 'Content Writing' },
  { path: '/service/content-writing', name: 'Content Writing' },
  { path: '/service/healthcare-video-marketing-agency', name: 'Videos & Flims' },
  { path: '/service/videos-films', name: 'Videos & Flims' },
  { path: '/service/media-planning-buying', name: 'Planning Buying' },
];
