import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SocialIcons from './SocialIcons';
import Navigation from './Navigation';
import useContent from '../hooks/useContent';
import Helmet from "react-helmet";
import bhaavLogo from '../assets/images/bhaav-logo-no.png';
import bhaavButterfly from '../assets/images/butterfly.gif';
import '../styles/components/header.scss';
import { useLocation } from 'react-router-dom';
const logoWithLink = (
  <Link className="logo-wrapper" to="/">
    <img src={bhaavLogo} className="img-fluid" alt="" />
    <img src={bhaavButterfly} className="logo-butterfly" alt="" />
  </Link>
);

export default function Header(props) { 
  const content = useContent();
  const { pathname } = useLocation(); 
  
  if(pathname == '/'){ 
    var context = 'Healthcare Digital Marketing Agency in gurgaon | Bhaav';
    var scripttype = '';
    var name = "Healthcare Digital Marketing Agency in gurgaon | Bhaav";
    var scriptimage = "";
    var descScript = ""
    var brandType = "Brand";
    var brandName = "Bhaav";

    var ogtitle = "Healthcare Digital Marketing Agency in gurgaon | Bhaav";
    var sitename = "Bhaav - Healthcare Digital Marketing Agency";
    var url = "https://bhaav.co.in";
    var image = "https://bhaav.co.in";
    var description = "Bhaav is an experienced healthcare digital marketing agency in Gurgaon India. We offer effective strategies that are tailored to meet your medical firm’s needs with higher conversions and improved search engine rankings";
    var type = "website";
  }else if(pathname == '/service/integrated-marketing'){ 
    var context = 'Healthcare Integrated Marketing Agency | Bhaav';
    var scripttype = '';
    var name = "Healthcare Integrated Marketing Agency | Bhaav";
    var scriptimage = "";
    var descScript = ""
    var brandType = "Brand";
    var brandName = "Bhaav";

    var ogtitle = "Healthcare Integrated Marketing Agency | Bhaav";
    var sitename = "Bhaav - Healthcare Digital Marketing Agency";
    var url = "https://bhaav.co.in/service/integrated-marketing";
    var image = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var description = "Bhaav is an experienced healthcare integrated marketing agency in India (Gurgaon). We offer strategies as per your medical firm’s needs. Leading to better conversions and rankings";
    var type = "website";
  }else if(pathname == '/service/creatives-branding'){ 
    var context = 'Healthcare Creative Designing Agency | Bhaav';
    var scripttype = '';
    var name = "Healthcare Creative Designing Agency | Bhaav";
    var scriptimage = "";
    var descScript = ""
    var brandType = "Brand";
    var brandName = "Bhaav";

    var ogtitle = "Healthcare Creative Designing Agency | Bhaav";
    var sitename = "Bhaav - Healthcare Marketing Agency";
    var url = "https://bhaav.co.in/service/creatives-branding";
    var image = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var description = "Bhaav is the best creative designing agency in India that provides services across various graphical needs like motion graphics, leaflets, infographics & many more. Request a free quote";
    var type = "website";
  }else if(pathname == '/service/digital-marketing'){ 
    var context = 'Healthcare Digital Marketing Agency | Bhaav';
    var scripttype = '';
    var name = "Healthcare Digital Marketing Agency | Bhaav";
    var scriptimage = "";
    var descScript = ""
    var brandType = "Brand";
    var brandName = "Bhaav";

    var ogtitle = "Healthcare Digital Marketing Agency | Bhaav";
    var sitename = "Bhaav - Healthcare Marketing Agency";
    var url = "https://bhaav.co.in/service/digital-marketing";
    var image = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var description = "Bhaav is an experienced healthcare digital marketing agency in India (Gurgaon). We offer strategies that are tailored to meet your medical firm’s needs. Leading to better traffic.";
    var type = "website";
  }else if(pathname == '/service/seo-campaigns'){ 
    var context = 'Healthcare seo Agency in India | Bhaav';
    var scripttype = '';
    var name = "Healthcare seo Agency in India | Bhaav";
    var scriptimage = "";
    var descScript = ""
    var brandType = "Brand";
    var brandName = "Bhaav";

    var ogtitle = "Healthcare seo Agency in India | Bhaav";
    var sitename = "Bhaav - Healthcare Marketing Agency";
    var url = "https://bhaav.co.in/service/seo-campaigns";
    var image = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var description = "Bhaav is an ethical Healthcare SEO agency & services in India (gurgaon). Our professional SEO experts provide you the guaranteed results that help your business grow.";
    var type = "website";
  }else if(pathname == '/service/sem-campaigns'){ 
    var context = 'Healthcare SEM Agency | Bhaav';
    var scripttype = '';
    var name = "Healthcare SEM Agency | Bhaav";
    var scriptimage = "";
    var descScript = ""
    var brandType = "Brand";
    var brandName = "Bhaav";

    var ogtitle = "Healthcare SEM Agency | Bhaav";
    var sitename = "Bhaav - Healthcare Marketing Agency";
    var url = "https://bhaav.co.in/service/sem-campaigns";
    var image = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var description = "Bhaav is the best healthcare SEM agency in India (gurgaon) providing top-quality services for branding, communication, newspaper advertisement, and Event Management.";
    var type = "website";
  }else if(pathname == '/service/social-media-campaigns'){ 
    var context = 'Healthcare Social Media Marketing Agency | Bhaav';
    var scripttype = '';
    var name = "Healthcare Social Media Marketing Agency | Bhaav";
    var scriptimage = "";
    var descScript = ""
    var brandType = "Brand";
    var brandName = "Bhaav";

    var ogtitle = "Healthcare Social Media Marketing Agency | Bhaav";
    var sitename = "Bhaav - Healthcare Marketing Agency";
    var url = "https://bhaav.co.in/service/social-media-campaigns";
    var image = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var description = "Bhaav is one of the best healthcare social media marketing agency in India (Gurgaon) offering social media marketing services. We will help your brand voice reach a target audience and generate leads.";
    var type = "website";
  }else if(pathname == '/service/website-development'){ 
    var context = 'Healthcare Website development Agency | Bhaav';
    var scripttype = '';
    var name = "Healthcare Website development Agency | Bhaav";
    var scriptimage = "";
    var descScript = ""
    var brandType = "Brand";
    var brandName = "Bhaav";

    var ogtitle = "Healthcare Website development Agency | Bhaav";
    var sitename = "Bhaav - Healthcare Marketing Agency";
    var url = "https://bhaav.co.in/service/website-development";
    var image = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var description = "Bhaav is the most trusted Healthcare Website Designing agency in India (Gurgaon), provides website revamping,digital marketing. 100% client satisfaction.";
    var type = "website";
  }else if(pathname == '/service/content-writing'){ 
    var context = 'Healthcare Content Writing Agency | Bhaav';
    var scripttype = '';
    var name = "Healthcare Content Writing Agency | Bhaav";
    var scriptimage = "";
    var descScript = ""
    var brandType = "Brand";
    var brandName = "Bhaav";

    var ogtitle = "Healthcare Content Writing Agency | Bhaav";
    var sitename = "Bhaav - Healthcare Marketing Agency";
    var url = "https://bhaav.co.in/service/content-writing";
    var image = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var description = "Bhaav has been providing the best healthcare content writing agency in India (gurgaon) . As a content writing agency, we have so far empowered more than 1000 clients across.";
    var type = "website";
  }else if(pathname == '/service/videos-films'){ 
    var context = 'Healthcare Video Marketing Agency | Bhaav';
    var scripttype = '';
    var name = "Healthcare Video Marketing Agency | Bhaav";
    var scriptimage = "";
    var descScript = ""
    var brandType = "Brand";
    var brandName = "Bhaav";

    var ogtitle = "Healthcare Video Marketing Agency | Bhaav";
    var sitename = "Bhaav - Healthcare Marketing Agency";
    var url = "https://bhaav.co.in/service/videos-films";
    var image = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var description = "Bhaav is a healthcare video marketing agency that creates professional videos of all types for brands and companies. Request a free video marketing strategy today.";
    var type = "website";
  }else if(pathname =='/service/healthcare-integrated-marketing-agency'){ 

    var context = 'https://schema.org/';
    var scripttype = 'Service';
    var name = "Healthcare Integrated Marketing Agency";
    var scriptimage = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var descScript = "We are an Healthcare integrated marketing agency and we bring all the services required to launch products, increase market share and build customer loyalty, through market research, branding, advertising, creative services, public and investor relations, web design and viral marketing together, under one roof."
    var brandType = "Brand";
    var brandName = "Bhaav";
 
    var ogtitle = "Healthcare Integrated Marketing Agency | Bhaav";
    var sitename = "Bhaav - Healthcare Marketing Agency";
    var url = "https://bhaav.co.in/service/healthcare-integrated-marketing-agency";
    var image = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var description = "Bhaav is an experienced healthcare integrated marketing agency in India (Gurgaon). We offer strategies as per your medical firm’s needs. Leading to better conversions and rankings";
    var type = "website"; 
  }else if(pathname =='/service/healthcare-creative-agency'){ 
    var context = 'https://schema.org/';
    var scripttype = 'Service';
    var name = "Healthcare Creative & Designing Agency";
    var scriptimage = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var descScript = "We healthcare creative designing agency offer creative services, advertising creatives, print media creatives, designs for website & social media. Creatives are a more interactive and interesting way to communicate with clients and help businesses to reach the right demographic audience in real-time."
    var brandType = "Brand";
    var brandName = "Bhaav";


    var ogtitle = "Healthcare Creative Designing Agency | Bhaav";
    var sitename = "Bhaav - Healthcare Marketing Agency";
    var url = "https://bhaav.co.in/service/healthcare-creative-agency";
    var image = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var description = "Bhaav is the best creative designing agency in India that provides services across various graphical needs like motion graphics, leaflets, infographics & many more. Request a free quote";
    var type = "website";
  }else if(pathname =='/service/healthcare-digital-marketing-agency'){ 

    var context = 'https://schema.org/';
    var scripttype = 'Service';
    var name = "Healthcare Digital Marketing Agency";
    var scriptimage = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var descScript = "We Healthcare Digital marketing agency, offer unique marketing solutions tailored as per your business need. Our robust digital marketing approach ensures exponential growth and popularity for your brand."
    var brandType = "Brand";
    var brandName = "Bhaav";


    var ogtitle = "Healthcare Digital Marketing Agency | Bhaav";
    var sitename = "Bhaav - Healthcare Marketing Agency";
    var url = "https://bhaav.co.in/service/healthcare-digital-marketing-agency";
    var image = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var description = "Bhaav is an experienced healthcare digital marketing agency in India (Gurgaon). We offer strategies that are tailored to meet your medical firm’s needs. Leading to better traffic.";
    var type = "website";
  }else if(pathname =='/service/healthcare-seo-agency'){ 

    
    var context = 'https://schema.org/';
    var scripttype = 'Service';
    var name = "Healthcare seo Agency";
    var scriptimage = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var descScript = "Bhaav is an ethical Healthcare SEO agency & services in India. Our professional SEO experts provide you the guaranteed results that help your business grow."
    var brandType = "Brand";
    var brandName = "Bhaav";

    var ogtitle = "Healthcare seo Agency in India | Bhaav";
    var sitename = "Bhaav - Healthcare Marketing Agency";
    var url = "https://bhaav.co.in/service/healthcare-seo-agency";
    var image = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var description = "Bhaav is an ethical Healthcare SEO agency & services in India (gurgaon). Our professional SEO experts provide you the guaranteed results that help your business grow.";
    var type = "website";
  }else if(pathname =='/service/healthcare-search-engine-marketing-agency'){ 

    var context = 'https://schema.org/';
    var scripttype = 'Service';
    var name = "Healthcare SEM Agency";
    var scriptimage = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var descScript = "the best healthcare SEM agency in India providing top-quality services for branding, communication, newspaper advertisement, and Event Management."
    var brandType = "Brand";
    var brandName = "Bhaav";


    var ogtitle = "Healthcare SEM Agency | Bhaav";
    var sitename = "Bhaav - Healthcare Marketing Agency";
    var url = "https://bhaav.co.in/service/healthcare-search-engine-marketing-agency";
    var image = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var description = "Bhaav is the best healthcare SEM agency in India (gurgaon) providing top-quality services for branding, communication, newspaper advertisement, and Event Management.";
    var type = "website";
  }else if(pathname =='/service/healthcare-social-media-marketing-agency'){ 

    var context = 'https://schema.org/';
    var scripttype = 'Service';
    var name = "Healthcare Social Media Marketing Agency";
    var scriptimage = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var descScript = "We Healthcare Social Media Marketing Agency (SMM offer a wide span across all of the major social networks such as Facebook, Twitter, Instagram, LinkedIn and YouTube. Our social media marketing tactics are developed to attain long-term value for your brand. We work beyond regular posting by making every activity strategically planned and analyzed."
    var brandType = "Brand";
    var brandName = "Bhaav";

    var ogtitle = "Healthcare Social Media Marketing Agency | Bhaav";
    var sitename = "Bhaav - Healthcare Marketing Agency";
    var url = "https://bhaav.co.in/service/healthcare-social-media-marketing-agency";
    var image = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var description = "Bhaav is one of the best healthcare social media marketing agency in India (Gurgaon) offering social media marketing services. We will help your brand voice reach a target audience and generate leads.";
    var type = "website";
  }else if(pathname =='/service/healthcare-website-development-agency'){
    
    var context = 'https://schema.org/';
    var scripttype = 'Service';
    var name = "Healthcare Website development agency";
    var scriptimage = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var descScript = "We Healthcare Website development agency focused on your brand idea and visitor conversion. The digital transformation of your brand message is solicited here with the effective use of technology and digital expertise. Your customer journey and experience is given prime focus while developing your website."
    var brandType = "Brand";
    var brandName = "Bhaav";

    var ogtitle = "Healthcare Website development Agency | Bhaav";
    var sitename = "Bhaav - Healthcare Marketing Agency";
    var url = "https://bhaav.co.in/service/healthcare-website-development-agency";
    var image = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var description = "Bhaav is the most trusted Healthcare Website Designing agency in India (Gurgaon), provides website revamping,digital marketing. 100% client satisfaction.";
    var type = "website";
  }else if(pathname =='/service/healthcare-content-writing-agency'){ 

    var context = 'https://schema.org/';
    var scripttype = 'Service';
    var name = "Healthcare Content Writing Agency";
    var scriptimage = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var descScript = "We healthcare content writing agency, create content in the form of blogs, articles, emails, images, press releases, etc, to promote a business, service or product. This indirect way of marketing is the best process of generating interests and trust for a brand in the consumer’s mind."
    var brandType = "Brand";
    var brandName = "Bhaav";

    var ogtitle = "Healthcare Content Writing Agency | Bhaav";
    var sitename = "Bhaav - Healthcare Marketing Agency";
    var url = "https://bhaav.co.in/service/healthcare-content-writing-agency";
    var image = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var description = "Bhaav has been providing the best healthcare content writing agency in India (gurgaon) . As a content writing agency, we have so far empowered more than 1000 clients across.";
    var type = "website";
  }else if(pathname =='/service/healthcare-video-marketing-agency'){ 

    var context = 'https://schema.org/';
    var scripttype = 'Service';
    var name = "Healthcare Video Marketing Agency";
    var scriptimage = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var descScript = "We healthcare video marketing agency, present the best communication medium to make your brand speak loud about its core ideas. The format of providing information has been redefined here in the most creative and communicative manner. Our experienced team handles the whole procedure of liaising with the client to delivering the final product with sheer brilliance."
    var brandType = "Brand";
    var brandName = "Bhaav";


    var ogtitle = "Healthcare Video Marketing Agency | Bhaav";
    var sitename = "Bhaav - Healthcare Marketing Agency";
    var url = "https://bhaav.co.in/service/healthcare-video-marketing-agency";
    var image = "https://bhaav.co.in/static/media/bhaav-logo-no.43e18b87.png";
    var description = "Bhaav is a healthcare video marketing agency that creates professional videos of all types for brands and companies. Request a free video marketing strategy today.";
    var type = "website";
  }
 
  return (
    <header className="header">
         <Helmet> 
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" /> 
      <meta property="og:title" content={ogtitle}/>
      <meta property="og:site_name" content={sitename}/>
      <meta property ="og:url" content={url}/>
      <meta property="og:image" content={image}/>
      <meta property="og:description" content={description}/>
      <meta property="og:type" content={type}/> 
      <script type="application/ld+json">
      {`
        "@context": "${context}", 
        "@type": "${scripttype}", 
        "name": "${name}",
        "image": "${scriptimage}",
        "description": "${descScript}",
        "brand": {  
          "@type": "${brandType}",
          "name": "${brandName}"
        }
      `}
      </script> 

    </Helmet>
      <div className="container">
        <div className="d-flex d-lg-block align-i tems-lg-start align-items-end mb-5">
          <div className="row">
            <div className="col-lg-12">
              <SocialIcons />
            </div>
            <div className="col-lg-12 position-static">
              <div className="row align-items-end">
                <div className="px-3">
                  <Navigation />
                </div>
                <div className="col-lg text-right header-text d-lg-block d-none">
                  {content.header.sentence}
                </div>
                <div className="px-lg-2 d-none d-lg-block">{logoWithLink}</div>
              </div>
            </div>
          </div>
          <div className="header-logo ml-auto d-lg-none">{logoWithLink}</div>
        </div>
        <div className="d-lg-none header-text text-justify">
          {content.header.sentence}
        </div>
      </div>
    </header>
  );
}
