import React from 'react';
import ReactDOM from 'react-dom';
import anime from 'animejs/lib/anime.es';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import AppWrapper from './components/AppWrapper';
import HomePage from './pages/HomePage';
import ServicePage from './pages/ServicePage';
import NotFoundPage from './pages/NotFound';
import { servicesPage } from './routes';
import ScrollToTop from './components/ScrollToTop';
// import PageLoader from './components/PageLoader';
import * as serviceWorker from './serviceWorker';

import './styles/main.scss';
import './styles/components/popup.scss';
import './styles/components/bubble.scss';

function toggleBubbleAnimation(state, elements, selectors) {
  if (state) {
    elements.forEach((elm) => elm.play());
  } else {
    selectors.forEach((sel) => anime.remove(sel));
  }
}

ReactDOM.render(
  <React.StrictMode>
    <AppWrapper>
      <HelmetProvider>
        <Router>
          <ScrollToTop />
          {/* <PageLoader /> */}
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <HomePage toggleBubbleAnimation={toggleBubbleAnimation} />
              )}
            />
            {servicesPage.map((service) => (
              <Route
                exact
                key={service.name}
                path={service.path}
                render={() => (
                  <ServicePage toggleBubbleAnimation={toggleBubbleAnimation} />
                )}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      </HelmetProvider>
    </AppWrapper>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();



