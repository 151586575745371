import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import closeIcon from '../assets/images/close-icon.svg';

export default function Popup({ className = '', show, toggleShow, children }) {
  const popupRef = useRef(null);

  const delayToggleShow = useCallback(() => {
    if (popupRef.current != null) popupRef.current.classList.add('is-hide');
    setTimeout(() => {
      toggleShow();
    }, 600);
  }, [toggleShow]);

  useEffect(() => {
    const body = document.querySelector('body');
    show
      ? body.classList.add('overflow-hidden')
      : body.classList.remove('overflow-hidden');

    document.addEventListener('keydown', (e) => {
      const key = e.key;
      if (key && key.toLowerCase() === 'escape' && show) {
        delayToggleShow();
      }
    });

    return () => body.classList.remove('overflow-hidden');
  }, [delayToggleShow, show]);

  useLayoutEffect(() => {
    if (show) {
      if (popupRef.current) {
        const button = popupRef.current.querySelector('button');
        if (button) {
          setTimeout(
            () =>
              button.scrollIntoView({ behavior: 'smooth', block: 'nearest' }),
            1000
          );
        }
      }
    }
  }, [show]);

  return !show
    ? null
    : createPortal(
        <>
          <div ref={popupRef} className={`popup ${className}`}>
            {children}
            <button
              onClick={delayToggleShow}
              type="buton"
              className="popup-close"
            >
              <img src={closeIcon} alt="" />
            </button>
            <div onClick={delayToggleShow} className="popup-backdrop" />
          </div>
        </>,
        document.querySelector('#modal-root')
      );
}
