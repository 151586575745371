import React, { useEffect } from 'react';
import anime from 'animejs/lib/anime.es';
import Gallery from '../Gallery';
import Popup from '../Popup';

const animOptions = {
  duration: 2500,
  direction: 'alternate',
  easing: 'linear',
  loop: true,
  autoplay: false,
};

const d =
  'M106.000,0.000 C156.909,-0.659 186.460,18.045 203.000,51.000 C208.000,65.999 213.000,81.001 218.000,96.000 C229.925,116.059 255.036,121.702 266.000,138.000 C290.290,174.108 225.485,229.155 203.000,240.000 C98.211,290.542 -31.084,182.339 7.000,73.000 C23.823,24.700 58.467,8.867 106.000,0.000 Z';

export default function Bubble6({
  show,
  toggleShow,
  galleryShow,
  toggleGalleryShow,
  content: {
    bubbles: { bubble6: bubble },
  },
  isAnimate,
  toggleBubbleAnimation,
}) {
  useEffect(() => {
    const anime1 = anime({
      ...animOptions,
      d: [
        {
          value:
            'M96.000,1.000 C146.909,0.341 167.460,11.045 184.000,44.000 C188.999,58.998 194.000,74.002 199.000,89.000 C210.925,109.059 246.036,122.702 257.000,139.000 C281.290,175.108 225.485,223.155 203.000,234.000 C98.211,284.542 -31.084,197.339 7.000,88.000 C23.823,39.700 44.668,5.748 96.000,1.000 Z',
        },
      ],
      targets: '.bubble-6-bg path',
    });

    const anime2 = anime({
      ...animOptions,
      targets: '.bubble-6-top path',
      d: [
        {
          value:
            'M129.000,1.000 C167.137,-0.149 211.940,26.895 196.000,65.000 C189.813,79.369 172.671,85.843 155.000,89.000 C136.443,92.316 112.847,92.227 94.000,89.000 C59.907,83.163 32.616,88.997 5.000,79.000 C-22.428,27.797 95.256,4.091 129.000,1.000 Z',
        },
      ],
    });

    toggleBubbleAnimation(
      isAnimate,
      [anime1, anime2],
      ['.bubble-6-bg path', '.bubble-6-top path']
    );
  }, [isAnimate, toggleBubbleAnimation]);

  const w = 2961.95;
  const h = 3120.26;

  return (
    <>
      <button
        onClick={toggleShow}
        type="button"
        className="no-btn-styles bubble bubble-6"
      >
        <svg
          className="bubble-item bubble-6-bg"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="bubble-6-mask">
            <path className="path" fill="rgb(0, 0, 0)" d={d} />
          </clipPath>
          <path fill="#33b7bc" d={d} />
          <image
            clipPath="url(#bubble-6-mask)"
            width="275"
            x="0"
            y="76"
            href={require('../../assets/images/bubble6/bg.gif')}
          />
        </svg>
        <svg
          className="bubble-item bubble-6-top"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="rgb(51, 183, 188)"
            d="M94.000,1.000 C132.137,-0.149 212.940,26.895 197.000,65.000 C190.813,79.369 173.671,78.843 156.000,82.000 C137.443,85.316 113.847,82.227 95.000,79.000 C60.907,73.163 33.616,88.997 6.000,79.000 C-21.428,27.797 60.256,4.091 94.000,1.000 Z"
          />
        </svg>
        <span className="bubble-item bubble-6-text">Website Wonders</span>
      </button>
      <Popup show={show} toggleShow={toggleShow}>
        <div className="popup-shape">
          <div
            className="popup-shape-block"
            style={{ paddingTop: `${(h / w) * 100}%` }}
          />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w} ${h}`}>
            <path
              fillRule="evenodd"
              fill="#00a859"
              d="M347.17 588.86c134.36,-184.4 319.25,-361.89 573.44,-494.52 0,0 525,-312.5 966.67,200 0,0 308.33,339.58 335.42,733.33 44.56,647.88 605.16,792.88 721.37,1182.33 41.35,138.6 19.3,297.55 -154.71,453.09 -470.83,420.83 -1150,457.06 -1150,457.06 0,0 -714.11,18.92 -1201.65,-451.5 -586.13,-565.54 -550.16,-1448.96 -90.52,-2079.79z"
            />
          </svg>
          <div className="row no-gutters align-items-center popup-content">
            <div className="col-xl-6 pr-xl-3 popup-content-body color-white">
              <h2 className="popup-content-title">{bubble.title}</h2>
              <p className="popup-content-sentence">{bubble.sentence}</p>
              <button
                onClick={toggleGalleryShow}
                type="button"
                className="btn-primary mb-3"
              >
                View our work
              </button>
            </div>
            <div className="col-xl-3">
              <img
                className="w-100"
                src={require(`../../assets/images/${bubble.img}`)}
                alt=""
              />
            </div>
          </div>
        </div>
      </Popup>
      <Gallery
        show={galleryShow}
        handleShow={toggleGalleryShow}
        images={bubble.gallery}
      />
    </>
  );
}
