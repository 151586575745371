import React from 'react';
import Popup from '../Popup';
import Gallery from '../Gallery';

export default function Bubble1({
  show,
  toggleShow,
  galleryShow,
  toggleGalleryShow,
  content: {
    bubbles: { bubble1: bubble },
  },
}) {
  return (
    <>
      <button
        type="button"
        onClick={toggleShow}
        className="no-btn-styles bubble bubble-1"
      >
        <img
          className="bubble-item bubble-1-item-0"
          src={require('../../assets/images/bubble1/1.png')}
          alt=""
        />
        <img
          className="bubble-item bubble-1-item-1"
          src={require('../../assets/images/bubble1/0.png')}
          alt=""
        />
        <img
          className="bubble-item bubble-1-item-2"
          src={require('../../assets/images/bubble1/4.gif')}
          alt=""
        />
        <div className="bubble-item bubble-1-item-3">OOH La La</div>
        <img
          className="bubble-item bubble-1-item-4"
          src={require('../../assets/images/bubble1/2.png')}
          alt=""
        />
      </button>

      <Popup show={show} toggleShow={toggleShow}>
        <div className="popup-shape">
          <div
            className="popup-shape-block"
            style={{ paddingTop: `${(3155.01 / 3768.32) * 100}%` }}
          />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3768.32 3155.01">
            <path
              fillRule="evenodd"
              fill="rgb(237, 47, 89)"
              d="M1784.54 0.88c706.41,23.8 1466.79,519.63 1795.99,1146.26 285.92,544.27 237.56,1053.66 -92.04,1547.68 -326.07,488.74 -577.73,551.6 -1098.53,359.14 -937.95,-346.63 -1011.88,209.25 -1635.54,-13.36 -154.82,-55.25 -311.44,-157.45 -436.41,-333.35 -195.53,-275.18 -347.15,-694.04 -313.23,-1137.37 67.34,-880.08 904.22,-1598.49 1779.77,-1569z"
            />
          </svg>
          <div className="d-xl-flex align-items-center popup-content">
            <div className="col-xl-5 pl-0 popup-content-body color-white">
              <h2 className="popup-content-title">{bubble.title}</h2>
              <p className="popup-content-sentence">{bubble.sentence}</p>
              <button
                onClick={toggleGalleryShow}
                type="button"
                className="btn-primary mb-xl-0 mb-3"
              >
                View our work
              </button>
            </div>
            <div className="col-xl-7 p-0">
              <img
                className="w-100"
                src={require(`../../assets/images/${bubble.img}`)}
                alt=""
              />
            </div>
          </div>
        </div>
      </Popup>
      <Gallery
        show={galleryShow}
        handleShow={toggleGalleryShow}
        images={bubble.gallery}
      />
    </>
  );
}
