import React from 'react';
import Gallery from '../Gallery';
import Popup from '../Popup';
import InnerContent from '../InnerContent';

export default function Bubble5({
  show,
  toggleShow,
  galleryShow,
  toggleGalleryShow,
  content: {
    bubbles: { bubble5: bubble },
  },
}) {
  return (
    <>
      <button
        onClick={toggleShow}
        type="button"
        className="no-btn-styles bubble bubble-3"
      >
        <img
          className="bubble-item bubble-1-item-0"
          src={require('../../assets/images/bubble5/1.png')}
          alt=""
        />
        <img
          className="bubble-item bubble-5-robot"
          src={require('../../assets/images/bubble5/robot.png')}
          alt=""
        />
        <div className="bubble-item bubble-5-text">
          <span>Build</span> <span>The</span> <span>Love</span>
        </div>
      </button>
      <Popup show={show} toggleShow={toggleShow}>
        <div className="popup-shape">
          <div
            className="popup-shape-block"
            style={{ paddingTop: `${(3394.98 / 3484.47) * 100}%` }}
          />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3484.47 3394.98">
            <path
              fillRule="evenodd"
              fill="#2a3136"
              d="M3135.11 486.1c397.22,369.21 480.51,958.41 120.71,1829.81 -404.56,1230.67 -2177.97,1472.42 -2918.96,393.24 -431.28,-628.14 -443.44,-1437.24 -65.19,-2008.7 601.51,-908.78 2145.11,-882.02 2863.44,-214.34z"
            />
          </svg>
          <div
            className="row no-gutters popup-content"
            style={{ paddingTop: '25%' }}
          >
            <div className="col-xl-5 pr-xl-3 m-0 popup-content-body color-white">
              <h2 className="popup-content-title text-uppercase ls-1">
                <InnerContent content={bubble.title} />
              </h2>
              <p className="popup-content-sentence">{bubble.sentence}</p>
              <button
                onClick={toggleGalleryShow}
                type="button"
                className="btn-primary mb-3"
              >
                View our work
              </button>
            </div>
            <div className="col-xl-7">
              <img
                className="w-100"
                src={require(`../../assets/images/${bubble.img}`)}
                alt=""
              />
            </div>
          </div>
        </div>
      </Popup>
      <Gallery
        show={galleryShow}
        handleShow={toggleGalleryShow}
        images={bubble.gallery}
      />
    </>
  );
}
