import React from 'react';
import { Link } from 'react-router-dom';
import QueryForm from './QueryForm';
import '../styles/components/footer.scss';

const footerNavigation = (
  <nav className="footer-navigation">
    <Link to="/">Home</Link>
    <span>|</span>
    <span>Services</span>
    <span>|</span>
    <Link to="/blog/" target="_blank">Blog</Link>  
    <br />
    <br />
    <Link to="/service/integrated-marketing">Integrated Marketing</Link>
    <span>|</span>
    <Link to="/service/creatives-branding">Creatives &amp; Branding</Link>
    <br />
    <Link to="/service/digital-marketing">Digital Marketing</Link>
    <br />
    <Link to="/service/seo-campaigns">SEO</Link>
    <span>|</span>
    <Link to="/service/sem-campaigns">SEM</Link>
    <span>|</span>
    <Link to="/service/social-media-campaigns">Social Media Campaigns</Link>
    <span>|</span>
    <Link to="/service/website-development">Website Development</Link>
    <span>|</span>
    <Link to="/service/content-writing">Content & Copywriting</Link>
    <br />
    <Link to="/service/videos-films">Videos &amp; Films</Link>
    
  </nav>
);

export default function Footer({ className = '' }) {
  return (
    <footer className={`footer ${className}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            {footerNavigation}
            <div className="d-flex mt-lg-4 mt-3 align-items-center footer-contact">
              <img
                src={require('../assets/images/phone-illustration.png')}
                alt=""
                className="mr-3"
              />
              <div>
                <p className="mb-1">
                  <span className="footer-r-text">Reach Us @</span>{' '}
                  <a className="footer-link" href="tel:7836917711">
                    78369 17711
                  </a>
                  {' | '}
                  <a className="footer-link" href="tel:01242574073">
                    0124 257 4073
                  </a>
                </p>
                <p className="mb-1">
                  Email Us{' '}
                  <a className="footer-link" href="mailto:info@bhaav.co.in">
                    info@bhaav.co.in
                  </a>
                </p>
                <p className="comp-copy mb-0 d-block text-uppercase">
                  A unit of aarohan healthcare services llp
                </p>
              </div>
              <div>
              <a href="https://www.google.com/partners/agency?id=5878023433" target="_blank"> 
              <img class='googlelogo' src="https://www.gstatic.com/partners/badge/images/2023/PartnerBadgeClickable.svg"/> 
            </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt-lg-0 mt-4">
            <QueryForm />
          </div>
        </div>
      </div>
    </footer>
  );
}
