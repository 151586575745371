import React, { lazy, Suspense } from 'react';
import useContent from '../hooks/useContent';
import Header from '../components/Header';
import MainSection from '../components/MainSection';
import CommunicationSection from '../components/CommunicationSection';
import OurClientSection from '../components/OurClientSection';
import Testimonials from '../components/Testimonials';
import Footer from '../components/Footer';
import DocumentMeta from '../components/DocumentMeta';

const BubblesSection = lazy(() => import('../components/BubblesSection'));

export default function HomePage({ toggleBubbleAnimation }) {
  const content = useContent();
  return (
    content.isLoaded && (
      <>
        <DocumentMeta title={content.home.title} meta={content.home.meta} />
        <Header />
        <MainSection toggleBubbleAnimation={toggleBubbleAnimation} />
        <CommunicationSection />
        <Suspense fallback={null}>
          <BubblesSection toggleBubbleAnimation={toggleBubbleAnimation} />
        </Suspense>
        <OurClientSection />
        <Testimonials />
        <Footer />
      </>
    )
  );
}
