import React from 'react';
import '../styles/components/communication-section.scss';

export default function CommunicationSection() {
  return (
    <section className="communication-section">
      <h2>Let us be your voice!</h2>
      <video
        loop
        muted
        autoPlay
        preload="auto"
        src={require('../assets/images/communication-bg.mp4')}
      ></video>
    </section>
  );
}
