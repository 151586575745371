import React, { useEffect } from 'react';
import anime from 'animejs/lib/anime.es';
import InnerContent from '../../components/InnerContent';
import Popup from '../Popup';
import Gallery from '../Gallery';

const animOptions = {
  duration: 2000,
  direction: 'alternate',
  easing: 'linear',
  loop: true,
  autoplay: false,
};

const d =
  'M262.000,79.000 C271.232,100.851 273.942,136.192 260.000,156.000 C228.794,200.334 180.592,158.835 144.000,180.000 C121.242,193.163 135.492,239.841 86.000,226.000 C29.812,210.286 -29.183,130.713 17.000,66.000 C60.230,5.424 207.701,-49.526 262.000,79.000 Z';

export default function Bubble7({
  show,
  toggleShow,
  galleryShow,
  toggleGalleryShow,
  content: {
    bubbles: { bubble7: bubble },
  },
  isAnimate,
  toggleBubbleAnimation,
}) {
  useEffect(() => {
    const anime1 = anime({
      ...animOptions,
      targets: '.bubble-7-bg path',
      keyframes: [{ skewY: '5deg' }],
    });
    const anime2 = anime({
      ...animOptions,
      targets: '.bubble-7-shape-left path',
      keyframes: [{ skewY: '3deg' }],
      d: [
        {
          value:
            'M125.000,1.000 C166.880,-0.249 209.912,21.141 186.000,55.000 C157.174,95.817 0.553,99.597 -0.000,42.000 C19.246,-2.558 38.354,32.827 80.000,23.000 C95.876,19.254 109.809,8.379 125.000,1.000 Z',
        },
      ],
    });
    toggleBubbleAnimation(
      isAnimate,
      [anime1, anime2],
      ['.bubble-7-bg path', '.bubble-7-shape-left path']
    );
  }, [isAnimate, toggleBubbleAnimation]);

  const w = 270;
  const h = 228;

  return (
    <>
      <button
        onClick={toggleShow}
        type="button"
        className="no-btn-styles bubble bubble-7"
      >
        <svg
          className="bubble-item bubble-7-bg"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="bubble-7-mask">
            <path fillRule="evenodd" fill="rgb(0, 0, 0)" d={d} />
          </clipPath>
          <image
            href={require('../../assets/images/bubble7/bg.jpg')}
            clipPath="url(#bubble-7-mask)"
          />
          <path
            fillRule="evenodd"
            fill="transparent"
            stroke="#eee"
            strokeWidth="2"
            d={d}
          />
        </svg>
        <svg
          className="bubble-item bubble-7-shape-left"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            stroke="#f7cfc3"
            strokeWidth="1"
            fill="rgba(195, 196, 198, 0.5)"
            d="M147.000,1.000 C188.880,-0.249 209.912,28.141 186.000,62.000 C157.174,102.817 0.553,106.597 -0.000,49.000 C19.246,4.442 60.354,32.827 102.000,23.000 C117.876,19.254 131.809,8.379 147.000,1.000 Z"
          />
        </svg>
        <span className="bubble-item text-center bubble-7-text">
          Making a <br /> Robot Trendy
        </span>
        <img
          className="case-study-icon"
          src={require('../../assets/images/case-study-icon.png')}
          alt=""
        />
      </button>
      <Popup className="p-0" show={show} toggleShow={toggleShow}>
        <div className="popup-shape popup-shape-fluid">
          <div
            className="popup-shape-block"
            style={{ paddingTop: `${(h / w) * 100}%` }}
          />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w} ${h}`}>
            <clipPath id="bubble7-popup-mask">
              <path fillRule="evenodd" d={d} />
            </clipPath>
            <path fillRule="evenodd" fill="#ffffff" d={d} />
            <image
              clipPath="url(#bubble7-popup-mask)"
              width="47%"
              x="55%"
              y="27%"
              href={require(`../../assets/images/${bubble.img}`)}
            />
          </svg>
          <div className="popup-content" style={{ paddingTop: '19.25%' }}>
            <div className="popup-content-body col-xl-9 px-xl-3 px-0">
              <h2 className="popup-content-title">{bubble.title}</h2>
              <div className="popup-content-sentence mb-5">
                <div className="popup-content-overflow-control">
                  <InnerContent content={bubble.sentence} />
                </div>
                <button
                  onClick={toggleGalleryShow}
                  type="button"
                  className="btn-primary btn-primary-dark mb-3"
                >
                  View our work
                </button>
                <img
                  className="d-xl-none d-block ml-auto img-fluid mr-n20"
                  src={require(`../../assets/images/${bubble.img}`)}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <Gallery
        show={galleryShow}
        handleShow={toggleGalleryShow}
        images={bubble.gallery}
      />
    </>
  );
}
