import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import useContent from '../hooks/useContent';
import '../styles/components/testimonials-section.scss';

export default function Testimonials() {
  const { testimonials } = useContent();

  return (
    <section className="testimonials-section">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="testimonials-slider">
              <div className="testimonials-slider-circle left">
                <span>
                  Client <br /> Testimonials
                </span>
              </div>
              <div className="testimonials-slider-circle right">
                <span>Direct Dil Se</span>
              </div>
              <div>
                <div className="testimonials-slider-body">
                  <Slider
                    {...{
                      arrows: false,
                      autoplay: true,
                      autoplaySpeed: 3000,
                    }}
                  >
                    {testimonials.map((testimonial, index) => (
                      <div key={`testimonials-${index}`}>
                        <p className="testimonials-slider-line">
                          {testimonial.content}
                        </p>
                        <p className="testimonials-slider-byline">
                          {testimonial.name}
                        </p>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
