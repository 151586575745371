import React from 'react';
import Popup from '../Popup';
import Gallery from '../Gallery';

const d =
  'M68.000,1.000 C232.482,-1.706 231.686,214.294 89.000,239.000 C47.648,246.160 12.315,218.327 20.000,178.000 C22.657,164.059 32.583,151.598 29.000,132.000 C24.782,108.931 5.786,97.890 1.000,78.000 C-5.210,52.190 25.630,19.073 39.000,11.000 C47.646,5.759 55.100,3.652 68.000,1.000 Z';

export default function Bubble2({
  show,
  toggleShow,
  galleryShow,
  toggleGalleryShow,
  content: {
    bubbles: { bubble2: bubble },
  },
}) {
  const w = 3353.28;
  const h = 3672.58;

  return (
    <>
      <button
        onClick={toggleShow}
        type="button"
        className="no-btn-styles bubble bubble-2"
      >
        <svg
          className="bubble-2-bg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 194 242"
        >
          <clipPath id="bubble-2-bg">
            <path
              className="bubble-2-bg2"
              fillRule="evenodd"
              fill="rgb(250, 166, 27)"
              d={d}
            />
          </clipPath>
          <path
            className="bubble-2-bg2"
            fillRule="evenodd"
            fill="rgb(250, 166, 27)"
            d={d}
          />
          <image
            x="52"
            y="70"
            clipPath="url(#bubble-2-bg)"
            href={require('../../assets/images/bubble2/2.png')}
          />
        </svg>
        <svg
          className="bubble-item bubble-2-shape-top"
          xmlns="http://www.w3.org/2000/svg"
          width="170px"
          height="110px"
          viewBox="0 0 170 110"
        >
          <path
            fillRule="evenodd"
            fill="rgb(250, 166, 27)"
            d="M90.000,1.000 C111.352,3.804 153.181,9.844 167.000,49.000 C169.134,55.047 171.861,65.012 168.000,72.000 C162.775,81.458 143.641,90.482 126.000,87.000 C122.519,86.313 94.351,87.054 92.000,86.000 C64.626,97.666 40.460,120.898 13.000,103.000 C-1.160,93.770 -1.529,68.516 5.000,54.000 C15.119,31.501 31.714,11.059 55.000,2.000 C64.332,0.000 76.031,-0.084 90.000,1.000 Z"
          />
        </svg>
        <svg
          className="bubble-item bubble-2-bottom"
          xmlns="http://www.w3.org/2000/svg"
          width="139px"
          height="183px"
          viewBox="0 0 139 183"
        >
          <path
            fillRule="evenodd"
            fill="rgb(254, 207, 77)"
            d="M34.000,-0.000 C72.019,4.432 70.124,46.956 87.000,73.000 C102.164,96.402 150.512,101.599 135.000,149.000 C130.859,161.655 123.435,170.963 112.000,176.000 C16.040,218.265 -37.953,39.041 34.000,-0.000 Z"
          />
        </svg>
        <span className="bubble-item bubble-2-text">
          Critical to <br /> go Digital
        </span>
      </button>
      <Popup show={show} toggleShow={toggleShow}>
        <div className="popup-shape">
          <div
            className="popup-shape-block"
            style={{ paddingTop: `${(h / w) * 100}%` }}
          />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w} ${h}`}>
            <path
              fillRule="evenodd"
              fill="rgb(251, 166, 26)"
              d="M162.04 720.04c0,0 672.59,-1153.01 2142.31,-540.92 563.84,234.82 833.85,644.05 952.5,1059.3 249.02,871.56 19.27,1698.8 -731.07,2226.45 -233.08,163.91 -513.26,260.89 -826.4,176.82 0,0 -491.1,-170.82 -580.06,-754.44 -88.97,-583.62 -487.54,-740.2 -487.54,-740.2 0,0 -1017.78,-338.07 -469.75,-1427.02z"
            />
          </svg>
          <div className="popup-content" style={{ paddingTop: '19%' }}>
            <div className="pr-xl-3 mb-5 popup-content-body">
              <h2 className="popup-content-title color-red">{bubble.title}</h2>
              <p className="popup-content-sentence color-base">
                {bubble.sentence}
              </p>
              <button
                onClick={toggleGalleryShow}
                type="button"
                className="btn-primary btn-primary-dark mb-xl-5 mb-3"
              >
                View our work
              </button>
            </div>
            <div className="p-0 col-xl-6 col-8 mx-auto">
              <img
                className="w-100"
                src={require(`../../assets/images/${bubble.img}`)}
                alt=""
              />
            </div>
          </div>
        </div>
      </Popup>
      <Gallery
        show={galleryShow}
        handleShow={toggleGalleryShow}
        images={bubble.gallery}
      />
    </>
  );
}
