import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import useContent from '../hooks/useContent';
import { useLocation } from 'react-router-dom';

import '../styles/components/navigation.scss';

const aboutContent = (line) => (
  <div
    className="navigation-content"
    dangerouslySetInnerHTML={{ __html: line }}
  />
);

const aroundTraingle = (text) => (
  <>
    <small className="text-xx-small">&#9650;</small> {text}
  </>
);

const NavLink = ({ match, to, className, children }) => {
  if (match.url === to) {
    return <span className={className}>{children}</span>;
  }
  return (
    <Link className={className} to={to}>
      {children}
    </Link>
  );
};

const navigation = (match) => (
  <nav className="navigation navigation-list align-items-start">
    <div className="navigation-item">
      <a
        match={match}
        className="navigation-link-item"
        href="/service/healthcare-integrated-marketing-agency	"
      >
        Integrated Marketing
      </a>
      <a
        match={match}
        className="navigation-link-item"
        href="/service/healthcare-creative-agency"
      >
        Creatives &amp; Branding
      </a>
    </div>
    <div className="navigation-item">
      <a
        match={match}
        href="/service/healthcare-digital-marketing-agency"
        className="navigation-link-item"
      >
        Digital Marketing
      </a>
      <a
        match={match}
        className="navigation-link-item"
        href="/service/healthcare-seo-agency"
      >
        {aroundTraingle('SEO Campaigns')}
      </a>
      <a
        match={match}
        className="navigation-link-item"
        href="/service/healthcare-search-engine-marketing-agency"
      >
        {aroundTraingle('SEM Campaigns')}
      </a>
      <a
        match={match}
        className="navigation-link-item"
        href="/service/healthcare-social-media-marketing-agency"
      >
        {aroundTraingle('Social Media Campaigns')}
      </a>
      <a
        match={match}
        className="navigation-link-item"
        href="/service/healthcare-website-development-agency"
      >
        {aroundTraingle('Website Development')}
      </a>
    </div>
    <div className="navigation-item">
      <a
        match={match}
        className="navigation-link-item"
        href="/service/healthcare-content-writing-agency"
      >
        Content & Copywriting
      </a>
      
      <a
        match={match}
        className="navigation-link-item"
        href="/service/healthcare-video-marketing-agency"
      >
        Videos &amp; Films
      </a>
      
    </div>
  </nav>
);

export default function Navigation() {
  const [isAbout, setIsAbout] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [key, setKey] = useState(false);
  const content = useContent();
  const { pathname } = useLocation();
  const match = useRouteMatch();

  const toggleShow = (eKey) => {
    if (eKey === key || !key || !isShow) setIsShow(!isShow);
  };

  useEffect(() => {
    if (isShow) setIsShow(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <nav className="navigation">
        <button
          type="button"
          onClick={() => {
            setIsAbout(true);
            toggleShow('about');
            setKey('about');
          }}
          className="navigation-link"
        >
          About Us
        </button>
        <button
          type="button"
          onClick={() => {
            setIsAbout(false);
            toggleShow('services');
            setKey('services');
          }}
          className="navigation-link"
        >
          Our Services
        </button>
      </nav>
      <div
        onMouseLeave={() => {
          setIsAbout(false);
          toggleShow(key);
          setKey(key);
        }}
        className={`navigation-container${isShow ? ' is-open' : ''}`}
      >
        <svg
          className="d-none d-lg-block"
          viewBox="0 0 4493.34 1203.39"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.902"
            fill="rgb(236, 27, 82)"
            d="M1333.33 146.72l-146.67 0c-57.06,0 -106.3,-3.35 -160,-3.33 -54.54,0.02 -105.28,-7.04 -156.63,-6.71 -76.83,0.48 -150.22,-10.54 -226.42,-16.91 -22.54,-1.89 -50.82,-4.66 -75.12,-8.21 -96.74,-14.15 -196.68,-19.13 -290.93,-35.74 -20.24,-3.56 -46.44,-8.3 -68.03,-11.96 -61.09,-10.37 -119.18,-15.49 -180.06,-26.73 -13.13,-2.43 -29.48,-9.63 -29.48,6.27l0 773.33c0,67.74 4.9,128.76 10,190 27.61,0 84.75,16.48 113.32,23.35 329.51,86.5 746.3,170.33 1077.39,171.09 12.77,0.03 8.38,2.23 22.62,2.23 498.65,0 917.78,-120 1408.17,-245.17 33.35,-8.29 60.6,-15.41 91.44,-25.23 543.58,-153.31 1254.52,-372.94 1770.4,-372.94l0 -373.33c-175.94,-40.99 -349.62,-98.07 -529.02,-127.65 -716.07,-118.02 -1394.39,-37.93 -2113.36,55.27 -152.28,19.74 -361.89,32.38 -517.62,32.38z"
          />
        </svg>
        <div className="container px-lg-3 px-0">
          {isAbout
            ? aboutContent(content.about, setIsAbout, toggleShow, setKey, key)
            : navigation(match)}
        </div>
      </div>
    </>
  );
}
